import { Navigate, useRoutes } from "react-router-dom";
//导入懒加载组件
import Home from "../views/Home";
import About from "../views/About";
import PrivacePolicy from "../views/PrivacePolicy";
import UserAgreement from "../views/UserAgreement";
import KantuPrivacePolicy from "../views/yunqiKantu/PrivacePolicy";
import KantuUserAgreement from "../views/yunqiKantu/UserAgreement";

// 首页模块
const homeRouter = [
  {
    path: "/wap",
    element: <Navigate to="/" />,
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/privacePolicy",
    element: <PrivacePolicy />,
  },
  {
    path: "/userAgreement",
    element: <UserAgreement />,
  },
  {
    path: "/yunqiKantu",
    element: <KantuPrivacePolicy />,
    children: [
      {
        path: "/yunqiKantu/privacePolicy",
        element: <KantuPrivacePolicy />,
      },
      {
        path: "/yunqiKantu/userAgreement",
        element: <KantuUserAgreement />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
];

const Routes = () => {
  const routes = useRoutes(homeRouter);
  return routes;
};

export default Routes;
