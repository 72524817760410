import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "./images/logo.svg";
import qrcode from "./images/qrcode.jpg";
import email from "./images/email.svg";
import styles from "./css/about.module.scss";
import Footer from "./Footer.jsx";
const About = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div>
      <header className={styles.header}>
        <img src={logo} />
        <div className={styles.nav}>
          <span
            style={{ marginRight: "50px" }}
            onClick={() => handleNavigate("/")}
          >
            首页
          </span>
          <span
            onClick={() => handleNavigate("/about")}
            style={{ color: "#ff4338" }}
          >
            关于我们
          </span>
        </div>
      </header>
      <div className={styles.banner}>
        <div>
          关&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;于&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;们
        </div>
        <div className={styles.title2}>About&nbsp;&nbsp;&nbsp;Us</div>
      </div>
      <main className={styles.main}>
        <div className={styles.part1}>
          <div className={styles.section}>
            <div className={styles.title}>
              <span className={styles.icon}></span>
              公司概览
            </div>
            <div className={styles.desc}>
              我们是一家致力于以科技创新为核心驱动力，秉承“诚信务实，科技创新，让天下没有难用的工具”为价值观的高新技术企业。专注于Windows平台下高效、易用的小工具开发，旨在通过我们的努力，为全球用户带来前所未有的便捷体验，让技术真正服务于每一个细微的生活与工作场景。
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>
              <span className={styles.icon}></span>
              产品特色
            </div>
            <div className={styles.desc}>
              我们的产品系列覆盖了Windows系统下的多种实用小工具，从日常办公的效率提升，到系统维护的便捷操作，每一款工具都经过精心设计与优化，确保用户能够轻松上手，即刻享受科技带来的便利。我们深信，好的工具应当如同贴心的助手，让复杂变得简单，让繁琐化为轻松。
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>
              <span className={styles.icon}></span>
              技术团队
            </div>
            <div className={styles.desc}>
              公司汇聚了一群来自清华大学、哈尔滨工业大学等国内顶尖学府的精英人才，他们不仅拥有扎实的专业基础，更具备丰富的实战经验。团队成员曾就职于百度、阿里巴巴等头部互联网大厂，在那里他们积累了宝贵的技术沉淀与行业洞察，如今将这些宝贵经验融入我们的产品之中，不断推动技术创新与产品迭代。
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>
              <span className={styles.icon}></span>
              企业文化
            </div>
            <div className={styles.desc}>
              “诚信务实”是我们企业文化的基石，我们坚持对每一位用户负责，对每一行代码负责，用实际行动践行承诺。同时，“科技创新”是我们不断前行的动力源泉，我们鼓励团队成员勇于探索未知，敢于挑战极限，用科技的力量解决用户痛点，创造更多可能。
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>
              <span className={styles.icon}></span>
              未来展望
            </div>
            <div className={styles.desc}>
              展望未来，我们将继续深耕Windows小工具领域，不断探索新技术、新应用，努力成为该领域的领航者。我们相信，通过我们的不懈努力，定能让“让天下没有难用的工具”这一愿景成为现实，为全球用户带来更加智能、高效、便捷的数字生活体验。
            </div>
          </div>
        </div>
        <div className={styles.part2}>
          <div className={styles.title}>联系我们</div>
          <div className={styles.qrcode}>
            <div className={styles.left}>
              <img src={qrcode} alt="" className={styles.qrimg} />
              <p className={styles.word}>微信公众号</p>
              <p className={styles.word}>请扫码关注</p>
            </div>
            <div className={styles.right}>
              <img
                src={email}
                alt=""
                className={styles.qrimg}
                style={{ width: "200px", height: "200px", padding: "20px" }}
              />
              <div className={styles.wordContent}>
                <p className={styles.word}>客服邮箱：service@yunqiboyuan.com</p>
                <p className={styles.word}>
                  商务合作：business@yunqiboyuan.com
                </p>
                <p className={styles.word}>联系电话：13611267273</p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default About;
